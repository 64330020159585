<template>
  <!-- 充值信息管理 -->
  <div>
    <div class="top"><p>充值信息管理</p></div>
    <el-form :model="ruleForm" label-width="150px">
      <el-form-item label="开户行">
        <el-input
          v-model="ruleForm.openBank"
          placeholder="请输入开户行名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="支行名称">
        <el-input
          v-model="ruleForm.subBranch"
          placeholder="请输入支行名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="单位名称">
        <el-input
          v-model="ruleForm.unitName"
          placeholder="请输入单位名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="银行卡号">
        <el-input
          v-model="ruleForm.cardNumber"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          placeholder="收款人银行卡号"
        ></el-input>
      </el-form-item>
      <el-form-item label="提示信息内容">
        <el-input
          type="textarea"
          v-model="ruleForm.tips"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {},
    };
  },
  created() {
    this.axios.get("/admin/account/findAll", {}).then((res) => {
      console.log(res);
      if (res.data.code == 200) {
        this.ruleForm = res.data.data;
      }
    });
  },
  methods: {
    save() {
      this.axios
        .put("/admin/account/updateAdminAccount", this.ruleForm)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "保存成功！",
              type: "success",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  padding: 10px;
}
</style>
